@use "sass:meta" as ---kha1f6wtc2;.inner {
  .breadcrumbs {
    li a {
      color: #989898;
      text-decoration: underline;
    }

    li:last-child {
      color: #ff0100;
      font-weight: bold;
    }

    &-section {
      margin-top: rem-calc(30);
    }

    li {
      text-transform: none;
      font-size: rem-calc(13);

      &:not(:last-child)::after {
        content: '>';

        @include breakpoint(small) {
          margin: 0 .45rem;
        }
      }
    }
  }

  .news,
  .article {
    .date {
      font-size: .9rem;
      margin-bottom: 1rem;
      color: #a5a5a5;
      font-weight: bold;
    }

    .inset {
      background-color: #f7f7f7;
      padding: 6px 26px;
      font-size: rem-calc(14);
      margin: 2rem;
      border-radius: 2rem;

      @include breakpoint(small only) {
        margin: 2rem .8rem;
      }

      >h3 {
        margin: 1rem 0;
      }
    }
  }

  .solution_title {
    span {
      display: block;
      color: #0ba28b;
      font-weight: 300;
      font-size: rem-calc(24);
    }
  }

  h1 {
    font-weight: 600;
    font-size: rem-calc(36);
    margin: rem-calc(20 0);
  }

  h2 {
    margin: rem-calc(30 20 20 0);
    color: #424242;
    font-weight: 600;
    font-size: rem-calc(26);
  }

  h3 {
    margin: rem-calc(30 20 20 0);
    color: #888787;
    font-weight: 600;
    font-size: rem-calc(20);
  }

  p {
    // text-indent: rem-calc(50);
    line-height: 1.6;
    color: #464646;

    &.note {
      font-size: rem-calc(12);
      font-weight: 400;
      line-height: 1.3;
      margin-top: rem-calc(3);
      font-style: italic;
    }

    &.str {
      text-indent: unset;
    }
  }

  td {
    border: 1px solid #d8d8d8;
  }

  article {
    ul,
    ol {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(30);
      color: #4d4d4d;

      @include breakpoint(small only) {
        padding-left: rem-calc(30);
      }

      @include breakpoint(medium) {
        padding-left: rem-calc(50);
      }

      li {
        &:not(:first-child) {
          margin-top: rem-calc(15);
        }
      }
    }
  }

  .arl-sticker {
    &::before {
      content: '';
      background-image: url('./img/svg/arlight-logo-white.svg');
      display: block;
      width: rem-calc(100);
      height: rem-calc(100);
      position: absolute;
      background-repeat: no-repeat;
      right: rem-calc(10);
      top: rem-calc(10);
      opacity: .7;
    }
  }

  .author {
    display: flex;
    font-weight: 500;
    font-size: rem-calc(13);

    img, svg {
      width: rem-calc(18);
      height: rem-calc(18);
      object-fit: contain;
      margin: rem-calc(0 7);
    }
  }

  .content {
    &-video {
      margin: 40px auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint(small only) {
        height: 0;
      }

      @include breakpoint(medium down) {
        width: 90%;
      }

      @include breakpoint(large) {
        width: 85%;
      }

      figcaption {
        font-size: rem-calc(13);
        margin-top: rem-calc(8);
        font-style: italic;
        color: #a7a7a7;
        text-align: center;
      }

      iframe {
        @include breakpoint(small only) {
          width: 100%;
        }

        @include breakpoint(large) {
          width: 800px;
          height: 400px;
        }
      }
    }
  }

  .orbit {
    margin: 0 auto 40px;

    @include breakpoint(medium down) {
      width: 90%;
    }

    @include breakpoint(large) {
      width: 85%;
    }

    &-image {
      border-radius: rem-calc(20);
    }
  }

  .delimiter-block {
    display: flex;
    flex-flow: row nowrap;
    font-size: rem-calc(36);
    gap: $global-margin;
    justify-content: center;
    margin-bottom: $global-margin;
    margin-top: $global-margin;
  }

  .recomendation {
    @include breakpoint(large down) {
      margin-top: rem-calc(50);
    }

    .adv {
      border-radius: rem-calc(20);
      background-color: #fff;
      border: 1px solid #efefef;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      @include breakpoint(xlarge) {
        margin-top: rem-calc(20);
      }

      &:hover {
        .title {
          color: #f00;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: rem-calc(20 20 0 0);
        border-bottom: 1px solid #f9f9f9;

        @include breakpoint(medium) {
          height: rem-calc(200);
        }

        @include breakpoint(xlarge) {
          width: rem-calc(300);
        }
      }

      .intro {
        padding: rem-calc(15 20);

        .title {
          font-weight: 700;
          line-height: 1.3;
          font-size: 1rem;
          transition: .5s;
        }

        .text {
          margin-top: rem-calc(15);
          font-size: rem-calc(13);
          height: rem-calc(115);
          overflow: hidden;
          color: #a7a7a7;
        }

        .date {
          display: flex;
          font-size: rem-calc(12);
          margin-top: rem-calc(20);
        }

        .author {
          display: flex;
          font-weight: 700;
          font-size: rem-calc(12);
          margin-top: rem-calc(20);

          img, svg  {
            width: rem-calc(18);
            height: rem-calc(18);
            object-fit: contain;
            margin: rem-calc(0 7);
          }
        }
      }
    }

    .list {
      background-color: #fff;
      overflow-y: hidden;
      border-radius: rem-calc(20);
      padding: rem-calc(25 20);
      border: 1px solid #efefef;

      @include breakpoint(small only, xlarge) {
        margin-top: rem-calc(20);
      }

      .title {
        font-weight: 700;
        font-size: rem-calc(21);
      }

      .item {
        font-weight: 500;
        font-size: rem-calc(13);

        @include breakpoint(large down) {
          margin-top: rem-calc(20);
          padding-top: rem-calc(15);
        }

        @include breakpoint(xlarge) {
          margin-top: rem-calc(10);
          padding-top: rem-calc(10);
        }

        &:hover {
          >a {
            color: #f00;
            transition: .5s;
          }
        }

        &:not(:nth-child(2)) {
          border-top: 1px solid #d7d7d7;
        }
      }

      .date {
        display: flex;
        font-size: rem-calc(12);
        margin-top: rem-calc(10);
      }

      .author {
        display: flex;
        font-weight: 700;
        font-size: rem-calc(12);
        margin-top: rem-calc(10);

        img, svg  {
          width: rem-calc(18);
          height: rem-calc(18);
          object-fit: contain;
          margin: rem-calc(0 7);
        }
      }
    }
  }

  .edu-arch {
    align-self: center;
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(20);

    &::before {
      content: '';
      position: relative;
      background-color: #ff0100;
      border-radius: .625rem;
      border-left: 6px solid #ff0100;
      margin-right: rem-calc(10);
    }

    a {
      color: #989898;
      transition: 1s;

      &:hover {
        font-weight: 500;
        color: #ff0100;
      }
    }
  }

  .content-detail {
    .image-block {
      margin: 0 auto 24px;
      text-align: center;

      @include breakpoint(medium down) {
        width: 90%;
      }

      @include breakpoint(large) {
        width: 85%;
      }

      &.with-border {
        img {
          border: 1px solid #f7f7f7;
          border-radius: 20px;
        }
      }

      &.stretched {
        width: 100%;

        img {
          max-height: max-content;
        }
      }

      &.with-background {
        background-color: #f7f7f7;
        padding: 2rem;
        border-radius: 20px;
      }

      img {
        border-radius: 20px;
        max-height: 440px;
      }

      figcaption {
        font-size: rem-calc(13);
        margin-top: 8px;
        font-style: italic;
        color: $dark-gray;
      }
    }

    a {
      color: map-get($foundation-palette, alert);
      text-decoration: none;
      cursor: pointer;
    }

    .grid-images, .swiper-slide {
      img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 4/3;
        border-radius: 20px;
      }
    }

    .grid-images, .swiper {
      .caption {
        font-size: rem-calc(13);
        color: #888787;
        font-style: italic;
        text-align: center;
        margin-top: 0.6rem;
      }
    }

    .grid-images {
      margin-bottom: 1rem;

      @include breakpoint(small only) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      .caption {
        text-align: center;
        margin-top: .6rem;
      }
    }

    .swiper {
      &.slider-article {
        margin-bottom: 1rem;
        width: 80%;

        @include breakpoint(small only) {
          width: 90%;
        }
      }

      &-button-prev, &-button-next {
        width: auto;
        margin-top: calc(-20px - var(--swiper-navigation-size) / 2);

        &::after {
          background-color: #edf2f5;
          opacity: .6;
          padding: 20px 6px;
          font-size: rem-calc(30);
          color: #8e8e8e;
        }
      }

      &-button-prev {
        left: 0;

        &::after {
          border-radius: 0 6px 6px 0;
        }
      }

      &-button-next {
        right: 0;

        &::after {
          border-radius: 6px 0 0 6px;
        }
      }
    }

    .information {
      background-color: #f7f7f7;
      padding: 20px;
      margin: 2rem 0;
      border-radius: 20px;

      h3 {
        color: #000;
        margin-top: 0;
      }

      p {
        font-size: rem-calc(14);

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:2095");