@use "sass:meta" as ---kha1f6wtc2;.event_announce {
  margin-right: rem-calc(15);
  border-radius: rem-calc(20);
  background-color: #fff;
  border: 1px solid #efefef;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @include breakpoint(small only) {
    margin-bottom: rem-calc(20);
  }

  &:hover {
    .title {
      color: #f00;
    }
  }

  img {
    object-fit: contain;
    padding: 2rem;
    object-position: center;
    border-radius: rem-calc(20 20 0 0);
    border-bottom: 1px solid #f9f9f9;
  }

  .intro {
    padding: rem-calc(15 20);

    .title {
      font-weight: 700;
      line-height: 1.3;
      font-size: 1rem;
      transition: .5s;
    }

    .date {
      margin-top: 1rem;
      font-size: 13px;
    }

    .text {
      margin-top: rem-calc(15);
      font-size: rem-calc(13);
      color: #a7a7a7;
    }

    .more {
      border-top: 1px solid #d7d7d7;
      margin-top: 1rem;
      padding-top: .6rem;
      font-size: rem-calc(13);
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:2126");