@use "sass:meta" as ---kha1f6wtc2;.map {
  .breadcrumbs {
    margin-top: rem-calc(30);

    li {
      text-transform: none;
      font-size: rem-calc(13);

      &:not(:last-child)::after {
        content: '>';

        @include breakpoint(small) {
          margin: 0 .45rem;
        }
      }
    }

    li a {
      color: #989898;
      text-decoration: underline;
    }

    li:last-child {
      color: #ff0100;
      font-weight: bold;
    }
  }

  .title {
    font-weight: 600;
    font-size: rem-calc(42);
    line-height: 1.2;
    margin: rem-calc(15 0 20);
    color: #424242;

    span {
      display: block;
      color: #0ba28b;
      font-weight: 300;
      font-size: rem-calc(24);
    }
  }

  &-block {
    position: relative;
    display: flex;
    justify-content: center;
    background: #f2f3f8;
    margin-top: 20px;
    border-top: 5px solid #d6d6d6;
    border-bottom: 5px solid #d6d6d6;

    img {
      align-self: baseline;
    }

    &.tablet {
      @include breakpoint(small only, xlarge) {
        display: none;
      }
    }

    &.mob {
      @include breakpoint(medium) {
        display: none;
      }
    }

    &.desktop {
      @include breakpoint(large down) {
        display: none;
      }
    }

    svg {
      position: absolute;
      max-width: 1400px;

      .garage {
        fill: #c5c5fc;
      }

      .kitchen {
        fill: #a4f3f9;
      }

      .laundryroom {
        fill: #ffc9c9;
      }

      .livingroom {
        fill: #f9ebb3;
      }

      .bedroom {
        fill: #a6ccf9;
      }

      .sauna {
        fill: #f7c219;
      }

      .bathroom {
        fill: #ccf4b2;
      }

      .hall {
        fill: #f9c897;
      }

      .vestibule {
        fill: #f9c897;
      }

      // dmx512

      .roof {
        fill: #f2e4ac;
      }

      .inserts {
        fill: #c5edab;
      }

      .entry {
        fill: #e59c9c;
      }

      .facadepanels {
        fill: #9fc5f2;
      }

      //knx

      .knx {
        &-openspace {
          fill: #ffc9c9;
        }

        &-meetingroom {
          fill: #f9ebb3;
        }

        &-manageroffice {
          fill: #f9c897;
        }

        &-serverroom {
          fill: #a4f3f9;
        }

        &-bathroom {
          fill: #babcf1;
        }

        &-hallway {
          fill: #a6ccf9;
        }

        &-reception {
          fill: #ccf4b2;
        }
      }

      //dali

      .dali {
        &-securityroom {
          fill: #806239;
        }

        &-administrationoffice {
          fill: #aab0f9;
        }

        &-hallway {
          fill: #e1fc70;
        }

        &-bathroom {
          fill: #037f35;
        }

        &-hall {
          fill: #80bff9;
        }

        &-manicuresalon {
          fill: #c8f0ae;
        }

        &-jewelrystore {
          fill: #f5c493;
        }

        &-clothingstore {
          fill: #a0eff5;
        }

        &-communicationsalon {
          fill: #fff599;
        }

        &-warehouse {
          fill: #ffc5c5;
        }

        &-supermarket {
          fill: #f2b41c;
        }
      }

      .zone {
        &.mob {
          opacity: .6;

          .dot {
            &_table-ty {
              transform: translate(650px, 500px);
            }

            &_table-dmx {
              transform: translate(560px, 180px);
            }
          }
        }

        &.tach {
          opacity: .6;

          // knx

          .dot-garage {
            transform: translate(640px, 160px);
          }

          .dot-kitchen {
            transform: translate(680px, 570px);
          }

          .dot-laundryroom {
            transform: translate(730px, 330px);
          }

          .dot-livingroom {
            transform: translate(380px, 600px);
          }

          .dot-bedroom {
            transform: translate(1100px, 590px);
          }

          .dot-sauna {
            transform: translate(790px, 400px);
          }

          .dot-bathroom {
            transform: translate(970px, 480px);
          }

          .dot-hall {
            transform: translate(460px, 310px);
          }

          .dot-vestibule {
            transform: translate(330px, 130px);
          }

          // dmx512

          .dot-roof {
            transform: translate(640px, 60px);
          }

          .dot-inserts {
            transform: translate(780px, 160px);
          }

          .dot-entry {
            transform: translate(360px, 200px);
          }

          .dot-facadepanels {
            transform: translate(540px, 145px);
          }

          // knx

          .knx {
            &_dot-reception {
              transform: translate(390px, 230px);
            }

            &_dot-openspace {
              transform: translate(310px, 450px);
            }

            &_dot-meetingroom {
              transform: translate(980px, 380px);
            }

            &_dot-manageroffice {
              transform: translate(555px, 160px);
            }

            &_dot-serverroom {
              transform: translate(705px, 105px);
            }

            &_dot-bathroom {
              transform: translate(635px, 200px);
            }

            &_dot-hallway {
              transform: translate(600px, 450px);
            }

            &_dot-table {
              transform: translate(700px, 400px);
            }
          }

          //dali

          .dali {
            &_dot-securityroom {
              transform: translate(610px, 620px);
            }

            &_dot-administrationoffice {
              transform: translate(550px, 750px);
            }

            &_dot-hallway {
              transform: translate(520px, 680px);
            }

            &_dot-bathroom {
              transform: translate(530px, 620px);
            }

            &_dot-hall {
              transform: translate(400px, 500px);
            }

            &_dot-manicuresalon {
              transform: translate(360px, 680px);
            }

            &_dot-jewelrystore {
              transform: translate(150px, 680px);
            }

            &_dot-clothingstore {
              transform: translate(150px, 230px);
            }

            &_dot-communicationsalon {
              transform: translate(462px, 370px);
            }

            &_dot-warehouse {
              transform: translate(462px, 160px);
            }

            &_dot-supermarket {
              transform: translate(1050px, 360px);
            }

            &_dot-table {
              transform: translate(700px, 400px);
            }
          }
        }

        .flash {
          opacity: 0;
          transition: opacity .8s ease;

          &:hover {
            opacity: .7;
            cursor: pointer;

            &.wrap-outline {
              stroke: #ff0100;
              stroke-miterlimit: 10;
              stroke-width: 1.9px;
            }
          }
        }

        .title-zone {
          font-weight: 500;
          font-size: 20px;
          text-shadow: 0 0 20px black, 0 0 6px black;
          fill: white;
        }
      }

      .brightness {
        @include breakpoint(large down) {
          display: none;
        }

        circle {
          fill: #fff;
          stroke: #000;
          stroke-miterlimit: 10;
          stroke-width: .25px;
        }

        text {
          font-size: 10px;
          fill: #4d4d4d;
          font-weight: 700;

          .value {
            letter-spacing: -.06em;
          }

          .units {
            font-size: 6.5px;
            font-weight: 400;
          }
        }
      }

      .device {
        @include breakpoint(large down) {
          display: none;
        }

        .line {
          fill: none;
          stroke: #333;
          stroke-width: .6px;
          stroke-miterlimit: 10;
          transition: all .3s ease;

          &-hover {
            stroke: #333;
            stroke-width: 1px;
          }
        }

        .red-dots {
          fill: red;
          stroke: #333;
          stroke-miterlimit: 10;
          stroke-width: .3px;
        }

        .title {
          font-size: 14px;
          fill: #4d4d4d;
          font-weight: 500;
        }

        .text-device {
          font-size: 11px;
          fill: #333;

          .art {
            font-size: 9px;
            font-weight: 700;
          }
        }

        a {
          &:hover {
            .wrap-fill {
              fill: #ff0100;
              opacity: .15;
            }

            .wrap-border {
              stroke: #ff0100;
              stroke-width: 2px;
            }
          }
        }

        &_laundryroom {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#laundryroom-device_a');
            }

            &-img_b {
              clip-path: url('#laundryroom-device_b');
            }

            &-img_с {
              clip-path: url('#laundryroom-device_c');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_garage {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#garage-device_a');
            }

            &-img_b {
              clip-path: url('#garage-device_b');
            }

            &-img_с {
              clip-path: url('#garage-device_c');
            }

            &-img_d {
              clip-path: url('#garage-device_d');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_sauna {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#sauna-device_a');
            }

            &-img_b {
              clip-path: url('#sauna-device_b');
            }

            &-img_c {
              clip-path: url('#sauna-device_c');
            }

            &-img_d {
              clip-path: url('#sauna-device_d');
            }

            &-img_f {
              clip-path: url('#sauna-device_f');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_bedroom {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#bedroom-device_a');
            }

            &-img_b {
              clip-path: url('#bedroom-device_b');
            }

            &-img_c {
              clip-path: url('#bedroom-device_c');
            }

            &-img_d {
              clip-path: url('#bedroom-device_d');
            }

            &-img_f {
              clip-path: url('#bedroom-device_f');
            }

            &-img_g {
              clip-path: url('#bedroom-device_g');
            }

            &-img_h {
              clip-path: url('#bedroom-device_h');
            }

            &-img_i {
              clip-path: url('#bedroom-device_i');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_kitchen {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#kitchen-device_a');
            }

            &-img_b {
              clip-path: url('#kitchen-device_b');
            }

            &-img_c {
              clip-path: url('#kitchen-device_c');
            }

            &-img_d {
              clip-path: url('#kitchen-device_d');
            }

            &-img_f {
              clip-path: url('#kitchen-device_f');
            }

            &-img_g {
              clip-path: url('#kitchen-device_g');
            }

            &-img_h {
              clip-path: url('#kitchen-device_h');
            }

            &-img_i {
              clip-path: url('#kitchen-device_i');
            }

            &-img_j {
              clip-path: url('#kitchen-device_j');
            }

            &-img_k {
              clip-path: url('#kitchen-device_k');
            }

            &-img_l {
              clip-path: url('#kitchen-device_l');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_livingroom {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#livingroom-device_a');
            }

            &-img_b {
              clip-path: url('#livingroom-device_b');
            }

            &-img_c {
              clip-path: url('#livingroom-device_c');
            }

            &-img_d {
              clip-path: url('#livingroom-device_d');
            }

            &-img_f {
              clip-path: url('#livingroom-device_f');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_bathroom {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#bathroom-device_a');
            }

            &-img_b {
              clip-path: url('#bathroom-device_b');
            }

            &-img_c {
              clip-path: url('#bathroom-device_c');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_vestibule {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#vestibule-device_a');
            }

            &-img_b {
              clip-path: url('#vestibule-device_b');
            }

            &-img_c {
              clip-path: url('#vestibule-device_c');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_hall {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#hall-device_a');
            }

            &-img_b {
              clip-path: url('#hall-device_b');
            }

            &-img_c {
              clip-path: url('#hall-device_c');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_entry {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#entry-device_a');
            }

            &-img_b {
              clip-path: url('#entry-device_b');
            }

            &-img_c {
              clip-path: url('#entry-device_c');
            }

            &-img_d {
              clip-path: url('#entry-device_d');
            }

            &-img_f {
              clip-path: url('#entry-device_f');
            }

            &-img_g {
              clip-path: url('#entry-device_g');
            }

            &-img_h {
              clip-path: url('#entry-device_h');
            }

            &-img_i {
              clip-path: url('#entry-device_i');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_inserts {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#inserts-device_a');
            }

            &-img_b {
              clip-path: url('#inserts-device_b');
            }

            &-img_c {
              clip-path: url('#inserts-device_c');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_facadepanels {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#facadepanels-device_a');
            }

            &-img_b {
              clip-path: url('#facadepanels-device_b');
            }

            &-img_c {
              clip-path: url('#facadepanels-device_c');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }

        &_roof {
          .wrap {
            fill: #fff;

            &-img_a {
              clip-path: url('#roof-device_a');
            }

            &-img_b {
              clip-path: url('#roof-device_b');
            }

            &-img_c {
              clip-path: url('#roof-device_c');
            }

            &-img_d {
              clip-path: url('#roof-device_d');
            }

            &-img_f {
              clip-path: url('#roof-device_f');
            }

            &-img_g {
              clip-path: url('#roof-device_g');
            }

            &-fill {
              fill: none;
              transition: all .8s ease;

              &-hover {
                fill: #ff0100;
                opacity: .15;
              }
            }

            &-border {
              fill: none;
              stroke: #333;
              stroke-miterlimit: 10;
              stroke-width: .9px;
              transition: all .3s ease;

              &-hover {
                stroke: #ff0100;
                stroke-width: 2px;
              }
            }
          }
        }
      }
    }
  }

  .device-table {
    margin-top: 60px;

    .zone {
      margin-top: 35px;

      h3 {
        color: #424242;
        font-size: 24px;
        font-weight: 600;

        span {
          color: #0ba28b;
          font-weight: 300;
        }
      }

      h4 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;

        &::before {
          content: '';
          position: relative;
          background-color: #ff0100;
          border-radius: .625rem;
          border-left: 6px solid #ff0100;
          margin-right: .625rem;
        }
      }

      &_title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .go-to-map {
          border: 1px solid #ff0100;
          padding: 5px 10px;
          border-radius: 20px;
          transition: all .8s ease;

          a {
            color: #ff0100;
            font-size: 13px;
          }

          &:hover {
            background-color: #ff0100;

            a {
              color: #fff;
            }
          }
        }
      }
    }

    table {
      margin-top: 20px;

      thead {
        color: #424242;
      }

      tbody {
        font-size: 14px;

        td:nth-child(odd) {
          @include breakpoint(large) {
            display: none;
          }

          @include breakpoint(medium down) {
            display: block;
            background-color: #f8f8f8;
            color: #424242;
            font-weight: bold;
            font-size: 16px;
          }
        }

        .device-table_items {
          @include breakpoint(medium only) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
          }

          a {
            // margin-right: 5px;
            margin: 10px 15px 10px 0;

            &.disable {
              pointer-events: none;

              /* делаем ссылку некликабельной */
              cursor: default;

              /* устанавливаем курсор в виде стрелки */
            }
          }

          &.row {
            display: flex;
            flex-wrap: wrap;

            // a {
            //   &:not(:first-child) {
            //     margin-left: 30px;
            //   }
            // }

            @include breakpoint(small only) {
              flex-direction: column;
            }
          }

          .item {
            display: flex;
            align-items: center;

            @include breakpoint(medium only) {
              margin-top: 10px;
            }

            .test-circle {
              clip-path: circle(40px at center);
              width: 56px;
              display: inline-block;
              border: 1px solid #868688;
              border-radius: 50%;
            }

            &_name {
              font-size: 11px;
              margin-left: 8px;
              text-decoration: underline;

              &:hover {
                color: #f00;
              }
            }
          }
        }
      }
    }

    .dawnload {
      margin-top: 20px;

      &-pdf {
        border: 1px solid #ff1920;
        border-radius: 20px;
        padding: 10px;
        width: 180px;
        color: #424242;
        font-size: 14px;
        display: flex;
        justify-content: center;
        transition: background-color .25s ease-out, color .25s ease-out;

        &::before {
          content: '';
          background-image: url('../img/svg/elements/dots.svg');
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          width: 25%;
          margin-right: 10px;
        }

        &:hover {
          border: 1px solid #fff;
          background-color: #037f35;
          color: white;
        }
      }
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:2242");