@use "sass:meta" as ---70mow6mkfwa;.events {
  &__list {
    .list-items {
      margin-top: rem-calc(25);
      padding-bottom: rem-calc(70);

      .item {
        border-radius: rem-calc(10);
        border: 1px solid #e6e6e6;
        overflow: hidden;
        transition: background-color, box-shadow, transform .5s ease-in-out;

        @include breakpoint(small only) {
          flex-direction: column;
        }

        &:hover {
          transform: scale(1.02);
          box-shadow: 0 9px 7px 0 #bbbbbb2e;
          background-color: #fff;
          border: 1px solid #efefef;

          h3 {
            color: #f00;
          }

          .tizer {
            cursor: pointer;
          }
        }

        &_info {
          @include breakpoint(medium) {
            display: flex;
          }

          height: 100%;
        }

        .img {
          position: relative;
          flex-shrink: 0;

          @include breakpoint(small only) {
            height: 210px;
          }

          @include breakpoint(medium only) {
            width: rem-calc(250);
          }

          @include breakpoint(large) {
            width: rem-calc(230);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &_event {
            display: flex;
            flex-shrink: 0;
            padding: 1rem;
            justify-content: center;
            align-items: center;

            @include breakpoint(medium) {
              width: 210px;
            }

            img {
              object-fit: contain;
            }
          }
        }

        .tizer {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;
          border-radius: rem-calc(0 10 10 0);
          padding: rem-calc(25 20);

          &_title {
            font-weight: 500;
            line-height: 1.3;
            font-size: 1.1rem;
            transition: 1s;
            color: #424242;
            margin: 0;
          }

          .text {
            margin-top: rem-calc(15);
            font-size: rem-calc(13);
            overflow: hidden;
            color: #676767;

            @include breakpoint(large only) {
              margin: 15px 0 0;
            }

            &.text-event {
              overflow: hidden;
              display: -webkit-box; /* stylelint-disable-line */
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .date-org {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin-top: 15px;
            gap: 3rem;

            @include breakpoint(large only) {
              margin: 15px 0 0;
            }

            .date {
              flex-shrink: 0;

              .day {
                display: flex;

                &::before {
                  content: '';
                  display: block;
                  background-image: url('../img/svg/elements/date-icon.svg');
                  width: rem-calc(18);
                  height: rem-calc(18);
                  background-repeat: no-repeat;
                  margin-right: rem-calc(8);
                }
              }

              .time {
                margin-top: 10px;
                display: flex;

                &::before {
                  content: '';
                  display: block;
                  background-image: url('../img/svg/elements/time-icon.svg');
                  width: rem-calc(18);
                  height: rem-calc(18);
                  background-repeat: no-repeat;
                  margin-right: rem-calc(8);
                }
              }
            }

            .locate {
              flex-basis: 500px;
              overflow: hidden;
              display: -webkit-box; /* stylelint-disable-line */
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            .info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;
            }

            .org {
              display: flex;

              img {
                width: 20px;
                height: 20px;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.event-detail {
  margin-bottom: 2rem;

  .event-title {
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
      flex-direction: column;
    }

    @include breakpoint(medium) {
      gap: 2.5rem;
    }

    .logo {
      flex-shrink: 0;
      padding: 1rem;

      @include breakpoint(medium) {
        width: 140px;
      }

      @include breakpoint(large) {
        width: 180px;
      }

      img {
        object-fit: contain;
      }
    }

    h1 {
      @include breakpoint(medium only) {
        font-size: 2rem;
      }
    }
  }

  .info {
    margin-bottom: 2rem;

    @include breakpoint(small only) {
      gap: 2rem;
    }

    @include breakpoint(large) {
      align-items: center;
    }

    &__event {
      display: flex;
      gap: 3rem;
      font-size: rem-calc(15);

      @include breakpoint(small only) {
        flex-direction: column;
        gap: .6rem;
      }

      span {
        color: #a7a6a6;
        font-weight: 500;
      }
    }

    &__date, &__org {
      display: flex;
      flex-direction: column;
      gap: .6rem;
    }

    &__link {
      @include breakpoint(medium) {
        display: flex;
        justify-content: center;
        align-self: center;
      }

      .btn-more {
        border: 1px solid red;
        padding: 15px 30px;
        border-radius: 30px;
        font-size: 14px;
        background-color: red;
        color: #fff;
        display: inline-block;
        cursor: pointer;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;

        @include breakpoint(medium only) {
          padding: 15px 20px;
        }

        &:hover {
          background-color: #fff;
          color: red;
        }
      }
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:1778");