@use "sass:meta" as ---kha1f6wtc2;.expert-advice {
  &__form {
    position: relative;

    @include breakpoint(small) {
      margin-top: rem-calc(100);

      &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: #dff4f2;
        width: rem-calc(270);
        height: rem-calc(270);
        border-radius: 50%;
        left: rem-calc(-160);
        bottom: 0;
        z-index: -1;
      }
    }

    .wrap {
      margin-top: rem-calc(40);
    }

    .logo {
      @include breakpoint(large down) {
        width: rem-calc(150);
      }

      @include breakpoint(xlarge) {
        width: rem-calc(200);
      }
    }

    h2 {
      margin-top: rem-calc(40);

      @include breakpoint(small only) {
        font-size: rem-calc(27);
        line-height: 1.2;
      }

      @include breakpoint(medium) {
        font-size: rem-calc(32);
      }
    }

    .data {
      margin-top: rem-calc(40);

      input,
      select,
      textarea {
        border-radius: rem-calc(10);
      }

      textarea[rows] {
        min-height: rem-calc(260);
      }
    }

    .button {
      background-color: #f00;
      border-radius: rem-calc(30);
      box-shadow: 0 3px 7px 0 #3131312e;
      color: #fff;
      margin-top: rem-calc(15);
      padding: 1.2em 1.5em;

      &:hover {
        background-color: #fff;
        box-shadow: none;
        border: 1px solid red;
        color: #4d4d4d;
      }

      &:active {
        background-color: #f00;
        color: #fff;
      }
    }

    .circle {
      position: relative;

      @include breakpoint(large) {
        &::before {
          content: '';
          display: block;
          position: absolute;
          background-color: #dff4f2;
          background-image: url('./img/contact.jpg');
          background-position: 30%;
          border: 5px solid #fff;
          width: rem-calc(270);
          height: rem-calc(270);
          border-radius: 50%;
          right: 0;
          top: rem-lac(90);
          z-index: -2;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: #f28584;
          border: 5px solid #fff;
          width: rem-calc(90);
          height: rem-calc(90);
          border-radius: 50%;
          right: rem-calc(20);
          top: rem-calc(50);
          z-index: -1;
        }
      }

      @include breakpoint(xlarge) {
        &::before {
          content: '';
          display: block;
          position: absolute;
          background-color: #dff4f2;
          background-image: url('./img/contact.jpg');
          background-position: 30%;
          border: 5px solid #fff;
          width: rem-calc(340);
          height: rem-calc(340);
          border-radius: 50%;
          right: 0;
          top: rem-calc(90);
          z-index: -2;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: #f28584;
          border: 5px solid #fff;
          width: rem-calc(90);
          height: rem-calc(90);
          border-radius: 50%;
          right: rem-calc(20);
          top: rem-calc(50);
          z-index: -1;
        }
      }

      .circle-2 {
        position: relative;

        @include breakpoint(large) {
          &::before {
            content: '';
            display: block;
            position: absolute;
            background-color: #7eaed5;
            width: rem-calc(210);
            height: rem-calc(210);
            border-radius: 50%;
            left: 0;
            top: rem-calc(20);
            z-index: -3;
          }
        }

        @include breakpoint(xlarge) {
          &::before {
            content: '';
            display: block;
            position: absolute;
            background-color: #7eaed5;
            width: rem-calc(260);
            height: rem-calc(260);
            border-radius: 50%;
            left: 0;
            top: rem-calc(20);
            z-index: -3;
          }
        }
      }
    }
  }

  &__reveal {
    border-radius: rem-calc(20);
    padding: rem-calc(50);
    display: flex;
    align-items: center;
    box-shadow: 0 5px 11px #5d5d5d;

    &::before {
      content: '';
      width: rem-calc(100);
      height: rem-calc(100);
      display: block;
      background-image: url('./img/svg/elements/done.svg');
      background-repeat: no-repeat;
      background-size: 90%;
      background-position: center;
    }

    .wrap {
      margin-left: rem-calc(30);

      .title {
        font-size: rem-calc(26);
        font-weight: bold;
      }

      p {
        margin: 0;
      }
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:2049");