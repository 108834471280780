@use "sass:meta" as ---kha1f6wtc2;.inner {
  .protocol {
    a {
      color: #ff0100;
      text-decoration: underline;
    }

    &-chapter {
      margin: rem-calc(25 0);

      @include breakpoint(small only) {
        display: none;
      }

      &_wrap {
        background-color: #fff;
        padding: rem-calc(10 0);

        &.is-stuck {
          padding: rem-calc(20 0);
        }
      }

      &_list {
        display: flex;
        list-style: none;
        margin: 0;

        li {
          &:not(:last-child) {
            margin-right: rem-calc(20);
          }

          a {
            border: 1px solid #ccc;
            padding: rem-calc(8 20);
            border-radius: rem-calc(30);
            font-size: rem-calc(14);
            color: #ccc;

            &:hover {
              background-color: #ff0100;
              color: #fff;
            }

            &.is-active {
              font-weight: 700;
              color: #fff;
              background-color: #ff151c;
              border-radius: rem-calc(30);
            }
          }
        }
      }
    }

    &-desc {
      margin-top: rem-calc(20);

      h1 {
        margin: 0;
      }

      &_content {
        padding-top: rem-calc(10);

        a {
          line-height: inherit;
          color: #ff0100;
          text-decoration: underline;
          cursor: pointer;
          font-weight: 400;
        }

        .list-link {
          padding: 0;
        }

        .tabs-panel {
          padding: 0;

          @include breakpoint(medium down) {
            display: block;
          }
        }
      }

      &_chapter {
        .tabs {
          border: none;
          position: relative;

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: rem-calc(6);
            height: 100%;
            background-color: red;
            border-radius: rem-calc(10);
            left: rem-calc(10);
          }

          .tabs-title {
            float: none;

            &:hover {
              a {
                font-weight: 500;
                color: #ff0100;
              }
            }
          }

          .tabs-title > a[aria-selected='true'] {
            background: none;
            font-weight: 500;
            color: #ff0100;
          }

          a {
            transition: 1s;
            font-size: rem-calc(16);
            line-height: 1.3;
            color: #989898;
            padding: rem-calc(10 35);

            &:focus {
              background: none;
            }
          }
        }
      }
    }

    &-typical {
      margin-top: rem-calc(50);

      &_item {
        display: flex;
        align-items: center;

        @include breakpoint(small only) {
          flex-direction: column;
        }

        &.disabled {
          opacity: .5;
          filter: grayscale(80%) blur(2px);
        }

        &:first-child {
          margin-top: rem-calc(20);
        }

        &:not(:first-child) {
          @include breakpoint(small only) {
            margin-top: rem-calc(50);
          }

          @include breakpoint(medium) {
            margin-top: rem-calc(35);
          }
        }

        figure {
          @include breakpoint(medium) {
            margin-right: rem-calc(25);
          }

          img {
            border-radius: rem-calc(20);
            width: rem-calc(370);
          }
        }
      }

      &_desc {
        .sub-title {
          color: #424242;
          font-weight: 500;
          font-size: rem-calc(20);
          margin: rem-calc(0 0 20 0);

          @include breakpoint(small only) {
            margin: rem-calc(30 0 20 0);
          }
        }

        .btn-wrap {
          display: flex;
          align-items: center;
        }
      }

      &_author {
        margin-left: rem-calc(20);
      }
    }

    &-doc {
      margin-top: rem-calc(100);

      >.title {
        font-weight: 700;
        font-size: rem-calc(28);
        line-height: 1.2;
      }

      &_items {
        margin-top: rem-calc(35);
      }

      .doc {
        display: flex;
        align-items: center;

        @include breakpoint(medium down) {
          &:not(:first-child) {
            margin-top: rem-calc(25);
          }
        }

        &::before {
          content: '';
          background-image: url('../img/svg/elements/pdf-icon.svg');
          width: rem-calc(40);
          height: rem-calc(40);
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          flex-shrink: 0;
        }

        &_data {
          font-size: rem-calc(14);
          margin-left: rem-calc(20);
          flex-grow: 2;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          a {
            color: #3d3d3d;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &_info {
          display: flex;
          margin-top: rem-calc(10);
          font-size: rem-calc(12);
          color: #a7a7a7;
          justify-content: space-between;
        }
      }

      .doc-link {
        display: flex;
        align-items: center;

        @include breakpoint(medium down) {
          &:not(:first-child) {
            margin-top: rem-calc(25);
          }
        }

        &::before {
          content: '';
          background-image: url('../img/svg/elements/http.svg');
          width: rem-calc(40);
          height: rem-calc(40);
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          flex-shrink: 0;
        }

        &_info {
          display: flex;
          margin-top: rem-calc(10);
          font-size: rem-calc(12);
          color: #a7a7a7;
          justify-content: space-between;
        }
      }
    }

    &-video {
      background-color: #f7f7f7;
      margin-top: rem-calc(100);
      padding: rem-calc(10 0 40);

      &_item {
        display: flex;
        align-items: center;

        &:hover {
          .title {
            font-size: rem-calc(16);
            color: #ff0100;
            margin: 0;
          }

          img {
            opacity: .8;
          }
        }

        @include breakpoint(small only) {
          &:nth-child(even) {
            margin-top: rem-calc(30);
          }
        }

        figure {
          margin-right: rem-calc(20);
          flex-shrink: 0;

          img {
            width: rem-calc(160);
            border: 1px solid #cacaca;
            border-radius: rem-calc(10);
            transition: .5s;
          }
        }
      }

      &_text {
        .title {
          font-size: rem-calc(16);
          color: #000;
          margin: 0;
          transition: 1s;
        }

        .intro {
          color: #898888;
          font-size: rem-calc(14);
        }
      }
    }

    &-news {
      margin-top: rem-calc(40);

      &_item {
        position: relative;
        border-radius: rem-calc(20);
        margin-top: rem-calc(20);
        background-color: #fff;
        min-height: rem-calc(350);
        border: 1px solid #efefef;
        transition: all .5s ease-in-out;
        flex-direction: column;
        display: flex;
        justify-content: space-between;

        &:nth-child(n+9) {
          display: none;
        }

        figure {
          img {
            height: rem-calc(200);
            width: 100%;
            border-radius: rem-calc(20 20 0 0);
            object-fit: cover;
          }
        }

        h3 {
          color: #000;
          font-size: rem-calc(16);
          margin: rem-calc(10 0 0 0);
        }

        &:hover {
          transform: scale(1.02);
          box-shadow: 0 3px 7px 0 #3131312e;
          border: 1px solid #d8cbcb;

          h3 {
            color: #f00;
          }
        }

        .info {
          padding: rem-calc(15 20);

          .text {
            margin-top: rem-calc(15);
            font-size: rem-calc(13);
            height: rem-calc(60);
            overflow: hidden;
            color: #a7a7a7;
          }
        }

        .author {
          margin-top: rem-calc(15);

          svg {
            width: rem-calc(18);
            height: rem-calc(18);
            object-fit: contain;
            margin: rem-calc(0 7);
          }
        }
      }
    }
  }

  .education {
    margin-top: rem-calc(100);

    .owl-carousel-event {
      overflow: hidden;

      &_wrap {
        display: flex;
      }

      &_container {
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(20);
      }

      &_nav {
        display: none;
      }

      &_dots {
        margin-top: rem-calc(20);
      }
    }

    .item {
      padding-left: rem-calc(5);

      @include breakpoint(small only) {
        &:first-child {
          margin-top: rem-calc(30);
        }

        &:nth-child(even) {
          margin-top: rem-calc(30);
        }
      }

      .event {
        border: 1px solid #cacaca;
        border-radius: rem-calc(20);
        transition: all .5s ease-in-out;

        &:hover {
          transform: scale(1.02);

          .intro {
            display: block;
          }

          &.arl {
            background-color: #fff;
          }
        }

        &.arl {
          background-color: #ffdadb;
        }

        &_img {
          position: relative;

          &::before {
            content: '';
            display: block;
            position: absolute;
            border-radius: rem-calc(20);
            background: rgb(0 0 0);
            background: linear-gradient(0deg, #0000004a, #0000002e, #0000);
            inset: 0;
          }

          &.arl-icon {
            &::after {
              content: '';
              background-image: url('../img/svg/arlight-logo-white.svg');
              background-repeat: no-repeat;
              height: rem-calc(30);
              width: rem-calc(120);
              top: rem-calc(15);
              right: rem-calc(10);
              position: absolute;
              opacity: .8;
            }
          }

          img {
            border-radius: rem-calc(20);
            border-bottom: 1px solid #c7c7c7;
          }

          &-title {
            position: absolute;
            bottom: 0;
            padding: rem-calc(30 20 12);

            h3 {
              color: #fff;
              font-weight: bold;
              font-size: rem-calc(20);
              line-height: 1.2;
              text-shadow: 0 0 20px black, 0 0 4px black;
            }

            .intro {
              font-size: rem-calc(12);
              color: #fff;
              text-shadow: 1px 1px 13px black, 1px 1px 5px black;
              display: none;
              transition: 5s ease-in-out;
            }
          }
        }

        &_info {
          display: flex;
          justify-content: space-between;
          font-size: rem-calc(12);
          padding: rem-calc(20);

          &-date {
            color: #888;

            .day {
              display: flex;
              align-items: center;

              &::before {
                content: '';
                display: block;
                background-image: url('../img/svg/elements/date-icon.svg');
                width: rem-calc(18);
                height: rem-calc(18);
                background-repeat: no-repeat;
                margin-right: rem-calc(8);
              }
            }

            .time {
              margin-top: rem-calc(8);
              display: flex;
              align-items: center;

              &::before {
                content: '';
                display: block;
                background-image: url('../img/svg/elements/time-icon.svg');
                width: rem-calc(18);
                height: rem-calc(18);
                background-repeat: no-repeat;
                margin-right: rem-calc(8);
              }
            }
          }

          &-org {
            display: flex;

            img {
              width: rem-calc(18);
              height: rem-calc(18);
              object-fit: contain;
              margin-right: rem-calc(10);
            }
          }
        }
      }
    }

    &-archiv {
      .list-items {
        margin-top: rem-calc(25);
        padding-bottom: rem-calc(70);

        .item {
          display: flex;
          border-radius: rem-calc(10);
          border: 1px solid transparent;
          background-color: #ffdadb;
          transition: background-color, box-shadow, transform .5s ease-in-out;

          @include breakpoint(small only) {
            flex-direction: column;
          }

          &:hover {
            transform: scale(1.02);
            box-shadow: 0 9px 7px 0 #bbbbbb2e;
            background-color: #fff;
            border: 1px solid #efefef;

            h3 {
              color: #f00;
            }

            .tizer {
              cursor: pointer;
            }
          }

          .img {
            position: relative;
            height: 100%;

            @include breakpoint(medium only) {
              width: rem-calc(250);
            }

            @include breakpoint(large) {
              width: rem-calc(230);
            }

            &.arl-icon {
              &::after {
                content: '';
                background-image: url('../img/svg/arlight-logo-white.svg');
                background-repeat: no-repeat;
                height: rem-calc(30);
                width: rem-calc(100);
                top: rem-calc(12);
                left: rem-calc(16);
                position: absolute;
                opacity: .8;
              }

              img {
                @include breakpoint(small only) {
                  object-fit: cover;
                  border-radius: rem-calc(10 10 0 0);
                  width: 100%;
                  max-height: 250px;
                }

                @include breakpoint(medium only) {
                  object-fit: cover;
                  border-radius: rem-calc(10 0 0 10);
                  width: 100%;
                  max-height: rem-calc(250);
                }

                @include breakpoint(large only) {
                  border-radius: rem-calc(10 0 0 10);
                  height: 100%;
                  object-fit: cover;
                }

                @include breakpoint(xlarge) {
                  border-radius: rem-calc(10 0 0 10);
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          .tizer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            border-radius: rem-calc(0 10 10 0);
            padding: rem-calc(25 20);

            &_title {
              font-weight: 500;
              line-height: 1.3;
              font-size: 1.1rem;
              transition: 1s;
              color: #424242;
              margin: 0;
            }

            .text {
              margin-top: rem-calc(15);
              font-size: rem-calc(13);
              overflow: hidden;
              color: #676767;

              @include breakpoint(large only) {
                margin: 15px 0 0;
              }
            }

            .date-org {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              margin-top: 15px;

              @include breakpoint(large only) {
                margin: 15px 0 0;
              }

              .date {
                .day {
                  display: flex;

                  &::before {
                    content: '';
                    display: block;
                    background-image: url('../img/svg/elements/date-icon.svg');
                    width: rem-calc(18);
                    height: rem-calc(18);
                    background-repeat: no-repeat;
                    margin-right: rem-calc(8);
                  }
                }

                .time {
                  margin-top: 10px;
                  display: flex;

                  &::before {
                    content: '';
                    display: block;
                    background-image: url('../img/svg/elements/time-icon.svg');
                    width: rem-calc(18);
                    height: rem-calc(18);
                    background-repeat: no-repeat;
                    margin-right: rem-calc(8);
                  }
                }
              }

              .org {
                display: flex;

                img {
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:2207");