@use "sass:meta" as ---70mow6mkfwa;.button {
  &.intro {
    background-color: #fefefe;
    border: 2px solid #fff;
    border-radius: rem-calc(20);
    color: #888787;
    margin: rem-calc(30 0 0 0);
    padding: rem-calc(10 15);
    transition: background-color .25s ease-out, color .25s ease-out;
    cursor: pointer;

    &:hover {
      background-color: #f00;
      color: #fff;
    }
  }

  &.read-more {
    margin-top: rem-calc(40);
    border-radius: rem-calc(20);
    padding: rem-calc(15);
    color: #888787;
    font-weight: 500;
    border: 1px solid #888787;

    &.btn_index {
      background-color: #f7f7f7;
    }

    &.btn_protocol {
      background-color: transparent;
    }

    &:hover {
      background-color: #989898;
      border: 1px solid #989898;
      color: #fff;
    }
  }
}

.solution-more {
  border: 1px solid red;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 14px;
  color: #4d4d4d;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  transition: background-color .25s ease-out, color .25s ease-out;

  &:hover {
    background-color: #f00;
    color: #fff;
  }

  &_disabled {
    border: 1px solid #c1c1c1;
    color: #c1c1c1;
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 14px;
    display: inline-block;
    cursor: default;
    pointer-events: none;

    &:hover {
      text-decoration: none;
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:1677");